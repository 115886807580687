.icon-bar-icons{
 
   font-size: 28px;
   background: none;
   border: none;
    color: rgba(0,0,0,0.8);
    &:hover{
    color: rgba(0,0,0,0.5);

    }
}