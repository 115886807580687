.top_bar-after-header{
    position: fixed;
    left:0;
    right: 0;
    top:113px;
    z-index: 1;
    background: var(--white);
}

.pagination{
    .controls{
        span{
           font-size: 80%;     
        }
        a{
         
        color: var(--dark-grey);
            // svg{
            //     position: relative;
            //     top:50%;
            //     left: 0%;
            //     transform: translate(0%, -50%)
            // }
            &.disabled{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
    svg{
        position: relative;
        top:0%;
        left: 0%;
        transform: translate(0%, 0%)
    }
}