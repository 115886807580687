@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap');
html,
body {
	margin: 0;
	font-family: 'Jost', sans-serif;
	font-display: swap;
	color: var(--dark-grey);
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
	letter-spacing: normal;
	text-rendering: optimizeLegibility;
	-moz-font-feature-settings: "kern=1";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	font-feature-settings: "kern";
	font-kerning: normal;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}
body{
    padding-top: 160px;
}
.login-container{
	margin-top: -170px;
	svg{
		width: 30px;
		height: 30px;
		z-index: 2;
		position: fixed;
		top:50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
.data-wrapper{
	min-height: calc(100vh - 300px);
	&.single{
		height: calc(100vh - 113px);
		min-height: auto;
		overflow: hidden;
		margin-top: -30px;
		.content-details{
			height: calc(100vh - 300px);
			min-height: auto;
			overflow-x: hidden;
			overflow-y: auto;
			
		}
		.border-left{
			border-left:1px solid #dee2e6 ;
		}
	}

	svg{
		width: 30px;
		height: 30px;
		z-index: 2;
		position: fixed;
		top:50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
.image-wrapper{
	// background:  #D9D9D9;
	height: calc(100vh - 115px);
	overflow-x: hidden;
	overflow-y: auto;
	.img-wrap{
		height: calc(100vh - 215px);
		img{
			height: auto;
			border: 2px solid transparent;
			width: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.show-list{
	.cards-wrapper{
		display: none;
	}
	.accordion-wrapper{
		display: flex;
	}
}
.show-cards{
	.cards-wrapper{
		display: flex;
		
	}
	.accordion-wrapper{
		display: none;
	}
}

.border-top{
	border-top: 1px solid #dee2e6!important;
}
