.header {
	background: var(--white);
	color: var(--black);
	top:0;
	z-index: 9;

	.navbar{
		// box-shadow: 0px 6px 15px #07060650;
		// background-color:var(--grey) !important;
		background-color:transparent !important;
	}

	a{
		color: var(--black);
		font-size: 18px;
		text-decoration: none;
		&:hover, &:active, &:focus{
			color: var(--black);
		}
		&.navbar-brand{
			font-size: 30px;
		}
	}
	.navbar-nav .nav-link {
		color: var(--dark-grey);
		font-size: 18px;
		&:hover, &:active, &:focus, &:visited{
			color: var(--black);
		}
		&.active{
			border-bottom: 2px solid var(--dark-grey);
		}
	}
	.active{
		.nav-link{
			border-bottom: 2px solid var(--dark-grey);
		}
	}
	.navbar-nav {
		width: 100%;
	}

	.dropdown-menu{
		width: 100vw ;
		padding: 2rem 0;
		border-top: 1px solid rgba(0,0,0,0);
	
		&.show{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
		.list-items{
			
			svg{
				width: 30px;
				height: 30px;
				display: flex;
				margin: 0 auto;
			}
			li{
				width: 100%;
				max-width:33%;
			
				a{
					display: block;
					color: var(--black);
					max-width: max-content;
					font-size: 14px;
					&:active{
						background: none;
						color: var(--black);

					}
		
				}
			}
		}
		
	}
	.search_form{
		max-width: calc(100% - .5rem);
		// margin-left:50%;
		// transform: translateX(calc(-50% - 8.333333335%));
		input{
			border-radius: 33px;
		}
		.submit-btn{
			right:10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.regular-border{
		border-radius: 0;
		min-width: 120px;
		text-decoration: none;
	}
}
