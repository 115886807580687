.accordion-custom-button {
    width: 30px;
    height: 30px;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") no-repeat transparent;
    border:none;
    margin-right: 2rem;
    &.visibility-hidden{
        visibility: hidden;
    }
 
}

.card-header{
    background: white;
    box-shadow: 0px 3px 2px 3px rgba(0,0,0,0.1);
   
}
.accordion-image{
    width: 100%;
    max-width: 100px;
}
.accordion-collapse{
    .card-header{
        background: rgba(0,0,0,0.1);
    }
}