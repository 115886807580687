.filter{
    position:fixed;
    background: var(--dark-grey);
    height: calc(100vh - 165px);
    overflow-y: visible;
    overflow-x: hidden;
    top: 165px;
    left: 0%;
    z-index: 99;
    transform: translateX(-100%);
    transition: .35s transform ease-in-out;
    ul{
        max-height: 400px;
        overflow-y: auto;
        li{
            a{
                color:var(--white);
            }
        }
    }
    .closeBtn{
        font-size: calc(1.325rem + .9vw);
        margin-right: 30px;
        margin-left: 15px;
        cursor:pointer;
        svg{
            fill: white;
        }
    }
    .accordion{
        background: var(--dark-grey);
        .accordion-button, .accordion-body{
            background: var(--dark-grey);
        }
        .accordion-item{
            border: none;
        }
        .accordion-button{
            border:none;
            font-weight: bold;
            svg{
                fill: white;
                margin-right: 30px;
            }
            &.collapsed{
            background: var(--dark-grey);
            }
            &:after{
                display: none;
            }
            &:focus {
                box-shadow: none;
            }
        }
       
       ul{
        li{
            a{
                text-decoration: none;
                font-weight: bold;
                cursor:pointer;
                margin-bottom: .5rem;
            }
        }
       }
    }
}

.show-filter{
    .filter{
        transform: translateX(0%);
    }
}