.card {
	border: none;
	.img-wrap{
		width: 100%;
		height: 200px;
		border: 1px solid rgba(0, 0, 0, 0.125);
		img{
			&.card-img-top{
				left: 50%;
				top: 50%;
				max-height: 100%;
				max-width: 100%;
				position: absolute;
				transform: translate(-50%, -50%);
				width: auto;
			}
		}
	}
	a{
		color:var(--black);
		text-decoration: none;
		&:hover{
			color:var(--dark-grey);
		}
	}
	.card-img-overlay {
		opacity: 0;
		background: rgba(0, 0, 0, 0.4);
		color: white;
		transition: opacity 0.25s ease-in-out;
		.card-title{
			font-weight: 700;
		}
		.card-text{
			font-size: 80%;
			font-weight: 500;
		}
	}
	&:hover {
		.card-img-overlay {
			opacity: 1;
		}
		.inner-cards{
			.card-img-overlay {
				opacity: 0;
			}
		}
	}

	.cards-custom-button {
		position:absolute;
		right: 7px;
		top: -20px;
		width: 30px;
		height: 30px;
		background: none;
		border:none;
		svg{
			position: relative;
			top: auto;
			left: auto;
			transform: translate(0%, 0%);
		}
		&.visibility-hidden{
			visibility: hidden;
		}
	 
	}
	.card-with-children, .btn-close{
		cursor: pointer;
	}

	.group-purchase{
		top: 86px;
		left: 0px;
		right: 0px;
		z-index: -1;
		background: none;
		transition: background .25s ease-in-out .2s;
		.btn-close, .inner-cards{
			opacity: 0;
			visibility: hidden;
			transition:opacity .25s ease-in-out 0 , visibility .25s ease-in-out 0s;
		}
		&.active{
			width: calc(100% - 3rem);
			min-height: 100%;
			z-index: 3;
			background: rgba(255,255,255,1);
			transition: background .25s ease-in-out .10s;
			.row-wrapper{
				overflow-y: auto;
				max-height: calc(100vh - 200px);
			}
			.btn-close, .inner-cards{
				opacity: 1;
				visibility: visible;
				transition:opacity .25s ease-in-out .2s, visibility .25s ease-in-out  .2s;
			}
			.inner-cards{
				&:hover {
					.card-img-overlay {
						opacity: 1;
					}
				}
			
			}

		}
	}
	
}
